import Header from "../components/header/header"
import React from "react"
import Section from "../components/section/section"
import Seo from "../components/seo"

const Contact = () => (
  <div>
    <Header />
    <Seo title="Contact" />

    <Section className="mt-5 pb-4">
      <h1>Got a project in mind?</h1>

      <p className="w-75">
        Let's talk about your product, customer experience, new ideas and how I
        can help you to grow your business by crafting impactful user
        experiences.
      </p>
    </Section>
    <Section className="pt-5 pb-5" background={true}>
      <div className="contact">
        Email:{" "}
        <a href="mailto:designwithslava@gmail.com">designwithslava@gmail.com</a>
      </div>
      <div className="contact">
        LinkedIn:{" "}
        <a
          href="https://www.linkedin.com/in/slava-kovalska/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Slava Kovalska
        </a>
      </div>
    </Section>
  </div>
)

export default Contact
